<div class="searchbar-container">
  <mat-card [ngClass]="{ 'mat-elevation-z0':outline, 'outline':outline }">
    <div *ngIf="!isSearchToolbarVisible" fxLayout="row" fxLayoutAlign="space-between center">
      <span>{{ title | titlecase }}</span>
      <div fxFlex></div>
      <button mat-icon-button (click)="toggleSearchToolbar()" [disabled]="isLoading" matTooltip="{{ 'app.actions.search' | translate | titlecase }}" *ngIf="includeSearchButton">
        <mat-icon aria-label="search">search</mat-icon>
      </button>
      <button mat-icon-button (click)="onButtonRefreshPressed()" [disabled]="isLoading" matTooltip="{{ 'app.actions.refresh' | translate | titlecase }}" *ngIf="includeRefreshButton">
        <mat-icon aria-label="refresh">cached</mat-icon>
      </button>
      <button mat-icon-button color="primary" (click)="onButtonCreatePressed()" matTooltip="{{ 'app.actions.create' | translate | titlecase }}" *ngIf="includeCreateButton">
        <mat-icon aria-label="add">add</mat-icon>
      </button>
    </div>
    <div *ngIf="isSearchToolbarVisible" fxLayout="row" fxLayoutAlign="space-between center">
      <input #searchInput autofocus fxFlex="100" [formControl]="searchQueryForm" placeholder="{{ 'app.actions.search' | translate | titlecase }}" class="mat-typography" />
      <div fxFlex></div>
      <button mat-icon-button (click)="toggleSearchToolbar()">
        <mat-icon aria-label="close">close</mat-icon>
      </button>
    </div>
  </mat-card>
  <mat-progress-bar mode="query" color="primary" style="height: 2px;" *ngIf="isLoading"></mat-progress-bar>
  <mat-progress-bar mode="query" color="accent" style="height: 2px;" *ngIf="isCreating"></mat-progress-bar>
</div>
