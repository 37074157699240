import { MatFormFieldDefaultOptions, MAT_FORM_FIELD_DEFAULT_OPTIONS } from "@angular/material/form-field";
import { MatPaginatorDefaultOptions, MAT_PAGINATOR_DEFAULT_OPTIONS } from "@angular/material/paginator";
// import { MatPaginatorIntl } from '@angular/material/paginator';


export const matFormFieldSettings: MatFormFieldDefaultOptions = {
  appearance: 'outline',
  hideRequiredMarker: false,
  floatLabel: 'always'
}

export const matPaginatorSettings: MatPaginatorDefaultOptions = {
  pageSize: 15,
  pageSizeOptions: [5, 10, 15, 20, 25, 50, 100],
  // formFieldAppearance: 'outline',
  showFirstLastButtons: true,
  hidePageSize: false
}

// export function matPaginatorDefaultIntl() {
//   const _matPaginatorIntl = new MatPaginatorIntl();
//
//   _matPaginatorIntl.itemsPerPageLabel = "{{ 'test' | translate }}";
//   _matPaginatorIntl.itemsPerPageLabel = "Registros por página";
//
//   return _matPaginatorIntl;
// }


export const MATERIAL_SETTINGS = [
  { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: matFormFieldSettings },
  { provide: MAT_PAGINATOR_DEFAULT_OPTIONS, useValue: matPaginatorSettings }
  // { provide: MatPaginatorIntl, useValue: matPaginatorDefaultIntl() }
]
