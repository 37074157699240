<div fxFlexFill class="sidenav-container">
  <mat-toolbar class="sidenav-container-toolbar">
    <div fxFlexFill fxLayout="row" fxLayoutAlign="end center">
      <mat-divider fxFlex class="sidenav-container-toolbar-line"></mat-divider>
      <!-- <img class="logo" src="assets/images/logo_white.png" alt="iPresas logo" /> -->
    </div>
  </mat-toolbar>
  <nav>
    <mat-list>
      <ng-container *ngFor="let module of modules">

        <!-- MODULE (SIMPLE) -->
        <div *ngIf="!module.submodules && module.sidenav">
          <mat-list-item matRipple [ngClass]="{ 'module-active': module.path === router.url }">
            <div fxFlex fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="0.5rem" routerLink="{{ module.path }}" routerLinkActive="active">
              <mat-icon mat-list-icon>{{ module.icon }}</mat-icon>
              <span>{{ module.label | translate | capitalize }}</span>
            </div>
            <!-- <mat-divider fxFlex></mat-divider> -->
          </mat-list-item>
        </div>

        <!-- MODULE (MULTIPLE SUBMODULES) -->
        <div *ngIf="module.submodules && module.sidenav">
          <mat-list-item matRipple (click)="module.expanded = !module.expanded">
            <div fxFlex fxLayout="row" fxLayoutAlign="space-between center">
              <div fxFlex fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="0.5rem">
                <mat-icon mat-list-icon>{{ module.icon }}</mat-icon>
                <span>{{ module.label | translate | capitalize }}</span>
              </div>
              <mat-icon class="submenu-button" [ngClass]="{ 'rotated': module.expanded }">expand_more</mat-icon>
            </div>
            <!-- <mat-divider fxFlex></mat-divider> -->
          </mat-list-item>
          <div class="submenu" [@collapse]="module.expanded">
            <ng-container *ngFor="let submodule of module.submodules">
              <mat-list-item matRipple [ngClass]="{ 'submodule-active': submodule.path === router.url }" *ngIf="submodule.sidenav">
                <div fxFlex fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="0.5rem" routerLink="{{ submodule.path }}" routerLinkActive="active">
                  <span>{{ submodule.label | translate | capitalize }}</span>
                </div>
              </mat-list-item>
            </ng-container>
          </div>
        </div>

      </ng-container>
    </mat-list>
  </nav>
</div>
