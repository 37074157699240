import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

import { IDialogData } from '../dialogs.interfaces';


@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent {

  title: string;
  message: string;

  constructor(@Inject(MAT_DIALOG_DATA) data: IDialogData) {
    this.title = data.title;
    this.message = data.message;
  }
}
