<div fxLayout="column" fxLayoutGap="0.5rem" class="image-upload-container">

  <!-- DROPZONE -->
  <div *ngIf="!file" (dragover)="onDropzoneOver($event)" (dragleave)="onDropzoneOut($event)" class="dropzone" [ngClass]="{ 'dropzone-over': inDropzone }">
    <input #fileDropReference fxFlex fxFill type="file" [accept]="fileFormatsRestriction" (change)="fileBrowseHandler($event.target.files)" [disabled]="disabled"/>
    <div fxFlex fxFill fxLayout="column" fxLayoutAlign="center center">
      <mat-icon>add</mat-icon>
    </div>
  </div>

  <!-- DROPZONE-PREVIEW -->
  <div *ngIf="file" class="preview" [style.backgroundImage]="sanitizeUri()">
    <input #fileDropReference fxFlex fxFill type="file" [accept]="fileFormatsRestriction" (change)="fileBrowseHandler($event.target.files)" [disabled]="disabled"/>
    <div *ngIf="!disabled" fxFlexFill fxLayout="column" class="contextual">
      <div fxFlex fxLayout="row" fxLayoutAlign="center center" class="edit">
        <button mat-icon-button (click)="fileDropReference.click()">
          <mat-icon>edit</mat-icon>
        </button>
      </div>
      <div fxFlex fxLayout="row" fxLayoutAlign="center center" class="delete">
        <button mat-icon-button (click)="resetFile()">
          <mat-icon>delete</mat-icon>
        </button>
      </div>
    </div>
  </div>

  <!-- RESTRICTIONS INFO -->
  <div fxLayout="column" fxLayoutAlign="start " fxLayoutGap="0.5rem" class="dropzone-restrictions" *ngIf="showRestrictions">
    <span *ngIf="fileFormatsRestriction"><strong>{{ 'upload-image.formats-restriction' | translate | titlecase }}:</strong> {{ fileFormatsRestriction }}</span>
    <span *ngIf="fileSizeRestriction"><strong>{{ 'upload-image.size-restriction' | translate | titlecase }}:</strong> {{ fileSizeRestriction }} MB</span>
  </div>

  <!-- ACTIONS -->
  <div fxLayout="column" fxLayoutAlign="end center" fxLayoutGap="0.5rem" *ngIf="file && showActions">
    <button fxFlexFill mat-raised-button color="primary" (click)="sendFile()">{{ buttonUpload | translate | titlecase }}</button>
    <button fxFlexFill mat-button (click)="resetFile()">{{ buttonReset | translate | titlecase }}</button>
  </div>
</div>
