import { HttpParams } from '@angular/common/http';

import { matPaginatorSettings } from '../material/material.settings';

// RESPONSE RESULT =============================================================
export interface ResultPaginated<T> {
  page: number,
  page_size: number,
  total: number,
  items: Array<T>,
  last_evaluated_key?: string;
}

// REQUEST RESULT ==============================================================
export interface RequestPagination {
  page?: number;
  page_size?: number;
  exclusive_start_key?: string;
}

export interface RequestOrder {
  order_field?: string;
  order_type?: 'asc' | 'desc';
}

export interface RequestFilter {
  filter: string;
  filter_field: string;
}

export interface RequestQuery {
  query?: string;
  query_fields?: string[];
}

export interface RequestEntity {
  field?: string;
  value?: string;
}

export class RequestParams {
  pagination?: RequestPagination = { page: 0, page_size: matPaginatorSettings.pageSize };
  order?: RequestOrder[];
  filter?: RequestFilter[];
  query?: RequestQuery;
  entity?: RequestEntity[];

  constructor() { }

  toHttpParams(): HttpParams {
    let params = new HttpParams();

    // MAPPED PAGINATION
    if (this.pagination) {
      if (this.pagination.page_size != null)
        params = params.set('_page_size', this.pagination.page_size.toString());

      if (this.pagination.page != null)
        params = params.set('_page', this.pagination.page.toString());

      if (this.pagination.exclusive_start_key != null)
        params = params.set('_last_evaluated_key', this.pagination.exclusive_start_key.toString());
    }

    // MAPPED ORDERS
    if (this.order) {
      let _values = '';

      for (const _order of this.order) {
        let _prefix = _order.order_type === 'desc' ? '-' : '';
        _values = `${_values},${_prefix}${_order.order_field}`
      }

      params = params.set('_order_by', _values.substring(1));
    }

    // MAPPED QUERY
    if (this.query) {
      if (this.query.query) {
        params = params.set('_operator', 'or');
        for (const key of this.query.query_fields) {
          params = params.set('_filter_' + key, '' + this.query.query);
        }
      }
    }

    // MAPPED FILTER
    if (this.filter) {
      for (const _filter of this.filter) {
        params = params.set('_filter_' + _filter.filter_field, _filter.filter);
      }
    }

    // MAPPED ENTITY
    if (this.entity) {
      for (const _entity of this.entity) {
        if (_entity.value)
          params = params.set(_entity.field, _entity.value);
      }
    }

    return params;
  }
}
