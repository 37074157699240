export interface ModuleInterface {
  label: string;
  icon?: string;
  path?: string;
  sidenav?: boolean;
  expanded?: boolean;
  permissions?: string[];
  submodules?: ModuleInterface[];
}


export const permissionsSettings: ModuleInterface[] = [
  {
    label: 'dashboard.title',
    icon: 'space_dashboard',
    path: '/dashboard',
    sidenav: true,
    permissions: [],
  },
  {
    label: 'image.listing',
    icon: 'photo_library',
    path: '/images',
    sidenav: true,
    permissions: [],
  },
  {
    label: 'image.input',
    icon: 'upload_file',
    path: '/input',
    sidenav: true,
    permissions: [],
  },
  {
    label: '_widgets.title',
    icon: 'widgets',
    path: '/widgets',
    sidenav: false,
    permissions: [],
  }
]

export const passwordSetting: string = '(?=[^A-Z]*[A-Z])(?=[^a-z]*[a-z])(?=[^0-9]*[0-9])(?=[^<>{}"/|;:.,~!?@#$%^=&*]*[<>{}"/|;:.,~!?@#$%^=&*]).{8,}'
