import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { trigger, state, style, animate, transition, AUTO_STYLE } from '@angular/animations';

import { permissionsSettings, ModuleInterface } from '@env/permissions-settings';
import { filter, take } from 'rxjs/operators';

@Component({
  selector: 'app-sidenav-primary',
  templateUrl: './sidenav-primary.component.html',
  styleUrls: ['./sidenav-primary.component.scss'],
  animations: [
    trigger('collapse', [
      state('true', style({ height: AUTO_STYLE, visibility: AUTO_STYLE })),
      state('false', style({ height: '0', visibility: 'hidden' })),
      transition('false => true', animate('250ms ease-in')),
      transition('true => false', animate('250ms ease-out'))
    ])
  ]
})
export class SidenavPrimaryComponent {
  rootPatter: RegExp = /[^/\\]+/;
  modules: ModuleInterface[] = permissionsSettings;

  constructor(public router: Router) {
    const onNavigationEnd = this.router.events.pipe(filter(event => event instanceof NavigationEnd));

    onNavigationEnd.pipe(take(1)).toPromise().then(
      (result: NavigationEnd) => {
        const _url = result.url.match(this.rootPatter);
        this.expandCurrentModule(_url ? _url[0] : '');
      }
    )
  }

  expandCurrentModule(root: string) {
    const _module = permissionsSettings.find(m => m.path === `/${root}`);
    if (_module)
      _module.expanded = true;
  }
}
