<div>
  <mat-card>
  <mat-card-title>{{"dashboard.select" | translate}}:</mat-card-title>
  <br>
  <div fxLayoutGap="2rem" fxLayoutAlign="center center" class="dashboard-container" fxLayout="row" fxLayout.xs="column" fxLayout.sm="column" fxLayout.md="column">
    <!-- <h3>{{"image.search" | translate}}:</h3> -->
    <app-searchbar title="Search Image ID" style="width: 100%;" fxFlex="40%" fxFlex.xs="100%" fxFlex.sm="100%" fxFlex.md="100%"
      [outline]="true"
      (searchQueryEvent)="searchQueryEvent($event)"
      [includeSearchButton]="true"
      [includeRefreshButton]='false'
      [includeCreateButton]='false'>
    </app-searchbar>
    <div fxLayoutGap="0.5rem" fxLayout="row">
      <button mat-raised-button routerLink='/images' style="font-size: 20px">
        <mat-icon >photo_library</mat-icon>
        {{"image.listing" | translate}}
      </button>
      <button mat-raised-button routerLink='/input' style="font-size: 20px">
        <mat-icon>upload_file</mat-icon>
        {{"image.input" | translate}}
      </button>
    </div>
  </div>
</mat-card>

<br>

<mat-card>
  <mat-card-title>{{"dataset.title" | translate}}</mat-card-title>
  <mat-card-content>
    <div fxFlex  fxLayout="row" fxLayout.lt-md="column">
      <div fxLayoutAlign="center center" fxFlex="44%" fxFlex.lt-md="100%">
        <img class="barplot" src="./assets/images/dashboard/percentage.png">
      </div>
      <div fxLayout="column" fxFlex="54%" fxFlex.lt-md="100%" fxLayoutGap="2rem">
        <app-dashboard-table fxLayout="center center"></app-dashboard-table>
        <p style="text-align: justify;">
          {{"dataset.paragraph" | translate}}.
          <a href="https://www.kaggle.com/competitions/rsna-intracranial-hemorrhage-detection" target="_blank">Competition Link.</a>
        </p>
      </div>
    </div>
  </mat-card-content>
</mat-card>
</div>
