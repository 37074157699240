import { Injectable } from '@angular/core';
import { Observable, Subscriber } from 'rxjs';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpResponse,
  HttpContext,
  HttpContextToken
} from '@angular/common/http';

import { CacheService } from '../services/cache.service';


export interface CacheContextToken {
  cache: boolean;
  forceUpdate: boolean;
}

const HTTP_CACHE = new HttpContextToken<CacheContextToken>(() => <CacheContextToken>{ cache: false, forceUpdate: false });

export function cache(forceUpdate: boolean = false): HttpContext {
  return new HttpContext().set(HTTP_CACHE, { cache: true, forceUpdate: forceUpdate });
}


/**
 * Include cache request (GET) to all requests
 */
@Injectable()
export class CacheInterceptor implements HttpInterceptor {

  constructor(private cacheService: CacheService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.method !== 'GET' || !request.context.get(HTTP_CACHE).cache) {
      return next.handle(request);
    }

    return new Observable((subscriber: Subscriber<HttpEvent<any>>) => {
      const cachedData = request.context.get(HTTP_CACHE).forceUpdate ? null : this.cacheService.getCacheData(request.urlWithParams);
      if (cachedData !== null) {
        // Create new response to avoid side-effects
        subscriber.next(new HttpResponse(cachedData as Object));
        subscriber.complete();
      }
      else {
        next.handle(request)
          .subscribe(
            event => {
              if (event instanceof HttpResponse) {
                this.cacheService.setCacheData(request.urlWithParams, event);
              }
              subscriber.next(event);
            },
            error => subscriber.error(error),
            () => subscriber.complete()
          );
      }
    });
  }

}
