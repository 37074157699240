<div fxLayout="column" fxLayoutGap="0.5erm" class="mat-typography">
  <h2 mat-dialog-title>{{ title | translate | uppercase }}</h2>

  <mat-dialog-content>
    <span>{{ message | translate }}</span>
  </mat-dialog-content>
  <br />
  <mat-dialog-actions fxLayout="row" fxLayoutGap="0.5rem">
    <button mat-raised-button #cancelBtn (keydown.arrowright)="acceptBtn.focus()" [matDialogClose]="false" fxFlex>
      {{ cancelButton | translate | titlecase }}
    </button>
    <button mat-raised-button #acceptBtn (keydown.arrowleft)="cancelBtn.focus()" [matDialogClose]="true" color="primary" fxFlex>
      {{ acceptButton | translate | titlecase }}
    </button>
  </mat-dialog-actions>
</div>
