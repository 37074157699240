import { Component, OnInit } from '@angular/core';

import { ImageService } from '@app/@shared/api/images.service';
import { Image } from '@app/@shared/models/image';
import { finalize } from 'rxjs/operators';

export interface SHAP_value {
  type: string;
  url: string;
}

@Component({
  selector: 'app-image-prediction',
  templateUrl: './image-prediction.component.html',
  styleUrls: ['./image-prediction.component.scss']
})
export class ImagePredictionComponent implements OnInit {
  image: Image;
  img_id: string;
  isLoading = false;
  SHAP_images: SHAP_value[];

  displayedColumns: string[] = ["type", "prediction"]

  constructor(private imageService: ImageService) { }

  ngOnInit() {
    this.getImage();
  }

  getImage() {
    this.isLoading = true;
    this.imageService.getPublicImage()
      .pipe(finalize(() => { this.isLoading = false; }))
      .subscribe((res: Image) => {
        this.image = res;

        // sort the predictions
        this.image.prediction.sort((n1, n2) => n2["prediction"] - n1["prediction"]);

        // save SHAP image url and type
        this.SHAP_images = []
        for (let key in this.image.prediction){
          this.SHAP_images.push(
            {
              type: this.image.prediction[Number(key)]["type"],
              url: this.image.shap[this.image.prediction[Number(key)]["type"]]
            }
          );
        }
      });
  }
}
