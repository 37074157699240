import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { LoaderService, untilDestroyed } from '@app/@core';

import { AWSAuthService, LoginContext } from '@app/@core/services/aws/auth.service';
import { DialogService } from '@app/@shared/services/dialog.service';
import { NotificationService } from '@app/@shared/services/notification.service';
import { AuthConfirmationAccountComponent } from '../confirmation-account/confirmation-account.component';
import { PasswordRefactorComponent } from '../password-refactor/password-refactor.component';

export enum LoginFormFields {
  username = 'username',
  password = 'password',
  remember = 'remember'
}

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class AuthLoginComponent implements OnInit {

  LoginFormFields = LoginFormFields;

  isLoading: boolean = false;
  hidePassword: Boolean = true;

  loginForm = new FormGroup({
    [LoginFormFields.username]: new FormControl(null, [Validators.required, Validators.email]),
    [LoginFormFields.password]: new FormControl(null, [Validators.required]),
    [LoginFormFields.remember]: new FormControl(true),
  });

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private awsAuthService: AWSAuthService,
    private loaderService: LoaderService,
    private dialogService: DialogService,
    private notificationService: NotificationService,) { }

  ngOnInit() {
  }

  login() {
    this.loginForm.value.username=this.loginForm.value.username.toLowerCase();
    const credentials: LoginContext = this.loginForm.value as LoginContext;

    this.isLoading = true;
    this.loaderService.activateNavigation();

    this.awsAuthService.login(credentials)
      .then((user: any) => {
        // [REFACTOR CREDENTIALS] Alternative manual process
        if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
          this.dialogService.open(PasswordRefactorComponent, { data: { user: user }, minWidth: '320px' } ).subscribe(
            () => {
              this.router.navigate([this.route.snapshot.queryParams.redirect || '/'], { replaceUrl: true });
              untilDestroyed(this);
            }
          );
        }

        // Login sucess > Redirect routing
        this.router.navigate([this.route.snapshot.queryParams.redirect || '/'], { replaceUrl: true });

        untilDestroyed(this);
      })
      .catch((error: any) => {
        if (error.code === 'UserNotConfirmedException') {
          this.dialogService.open(AuthConfirmationAccountComponent, { data: { email: credentials.username },  minWidth: "320px" }).subscribe(
            (result) => {
              if (result)
                this.login();
            }
          );
        }
        else if (error.code === 'NotAuthorizedException' && error.message.includes('User is disabled')) {
          this.notificationService.open("auth.login.authentication disabled");
        }
        else if (error.code === 'NotAuthorizedException' && error.message.includes('Incorrect username or password')) {
          this.notificationService.open("auth.login.authentication incorrect");
        }
        else {
          this.notificationService.open("auth.login.authentication failed");
        }
      })
      .finally(() => {
        this.isLoading = false;
        this.loaderService.deactivateNavigation();
      })
  }
}
