import { Component, EventEmitter, HostListener, OnInit, Output } from '@angular/core';

import { ImageListItem } from '@shared/models/image';

import { ImageService } from '@app/@shared/api/images.service';
import { RequestParams } from '@app/@shared/models/_results';
import { Router } from '@angular/router';
import { PageEvent } from '@angular/material/paginator';
import { windowCount } from 'rxjs/operators';


@Component({
  selector: 'app-image-list',
  templateUrl: './image-listing.component.html',
  styleUrls: ['./image-listing.component.scss']
})
export class ImageListingComponent implements OnInit {

  constructor(private imageService: ImageService, private router: Router) {}

  images: ImageListItem[] = [];
  page_size = 10;
  current_page = 1;
  last_evaluated_keys = new Map<number, string>();
  has_previous_page = false;
  has_next_page = true;


  @Output() click = new EventEmitter<ImageListItem>();

  ngOnInit() {
    // load state
    this.onResize();

    this.page_size = Number(sessionStorage.getItem("page_size")) || this.page_size;
    this.current_page = Number(sessionStorage.getItem("current_page")) || this.current_page;
    this.last_evaluated_keys = new Map<number, string>(JSON.parse(sessionStorage.getItem("last_evaluated_keys"))) || this.last_evaluated_keys;
    this.has_next_page = sessionStorage.getItem("has_next_page") ? sessionStorage.getItem("has_next_page")=="true" : this.has_next_page;
    this.has_previous_page = sessionStorage.getItem("has_previous_page") ? sessionStorage.getItem("has_previous_page")=="true" : this.has_previous_page;

    this.getImages();
  }

  onImageListItemClick(imageListItem: ImageListItem) {
    this.router.navigate(["/image"], {queryParams: { id: imageListItem.id }});
  }

  handlePageEvent(event: PageEvent){
    this.current_page = event.pageIndex;
    this.has_previous_page = this.current_page>1;
    this.has_next_page = this.last_evaluated_keys.get(this.current_page)!=undefined;

    this.getImages();
  }

  getImages(){
    this.saveState();

    const req_params = new RequestParams();
    req_params.pagination.page_size = Number(sessionStorage.getItem("page_size"));
    req_params.pagination.page = Number(sessionStorage.getItem("current_page"));

    const _last_evaluated_key = new Map<number, string>(JSON.parse(sessionStorage.getItem("last_evaluated_keys"))).get(this.current_page-1);

    if (_last_evaluated_key)
      req_params.pagination.exclusive_start_key = _last_evaluated_key;

    this.imageService.getImages(req_params).then(
      (result) => {
        this.images = [];

        for(let elem of result.items){
          this.images.push({src: elem.url, caption: elem.id, id: elem.id});
        }

        const _last_evaluated_key = result.last_evaluated_key
        if(_last_evaluated_key){
          this.last_evaluated_keys.set(this.current_page, _last_evaluated_key);
          this.has_next_page = true;
        } else {
          this.has_next_page = false;
        }
      }, (error) => {console.log(error)})
  }

  saveState(){
    sessionStorage.setItem("page_size", this.page_size.toString());
    sessionStorage.setItem("current_page", this.current_page.toString());
    sessionStorage.setItem("last_evaluated_keys", JSON.stringify(Array.from(this.last_evaluated_keys.entries())));
    sessionStorage.setItem("has_previous_page", String(this.has_previous_page));
    sessionStorage.setItem("has_next_page", String(this.has_next_page));
  }

  @HostListener('window:resize', ['$event']) onResize() {
    const _innerHeight = window.innerWidth
    var _new_page_size = this.page_size

    if (_innerHeight <= 1366){
      _new_page_size = 6
    }
    else if (_innerHeight < 1864) {
      _new_page_size = 8
    }
    else {
      _new_page_size = 10
    }

    if (_new_page_size != this.page_size){
      this.page_size = _new_page_size
      this.current_page = 1;
      this.last_evaluated_keys = new Map<number, string>();

      this.has_previous_page = false;
    }
    this.getImages();
  }

  getText(): string{
    return `calc( ${1/(this.page_size/2)*100}% - 1rem)`
  }
}
