import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { from, Observable } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { Logger } from '../services/logger.service';

import { AWSAuthService } from '../services/aws/auth.service';

const log = new Logger('ErrorHandlerInterceptor');


/**
 * Set authorization header in all requests.
 */
@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  constructor(
    public awsAuthService: AWSAuthService,
    private router: Router
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return from(this.awsAuthService.currentSession()) // Promise --> Observable
      .pipe(
        switchMap((session: any) => {
          const token = session.getIdToken().getJwtToken();
          const _request = request.clone({
            setHeaders: { Authorization: `Bearer ${token}` }
          });

          return next.handle(_request);
        }),
        catchError((error) => {
          log.error("[HTTP ERROR]", error);

          if (error.status === 401) {
            // AUTO > Logout if API return 401 code
            return this.awsAuthService.logout()
              .finally(() => this.router.navigate(['/auth'], { replaceUrl: true }));
          }

          return next.handle(request);
        })
      );
  }
}
