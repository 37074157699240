import { NgModule } from '@angular/core';
import { SharedModule } from '@shared';

import { AuthRoutingModule } from './auth-routing.module';

import { AuthComponent } from './auth.component';
import { AuthConfirmationAccountComponent } from './confirmation-account/confirmation-account.component';
import { AuthLoginComponent } from './login/login.component';
import { PasswordRefactorComponent } from './password-refactor/password-refactor.component';
import { PasswordChangeComponent } from './password-change/password-change.component';
import { AuthRegisterComponent } from './register/register.component';
import { ImageDescriptionComponent } from './image-description/image-description.component';
import { ImagePredictionComponent } from './image-description/image-prediction/image-prediction.component';


@NgModule({
  imports: [
    AuthRoutingModule,
    SharedModule
  ],
  declarations: [
    AuthComponent,

    AuthLoginComponent,
    AuthRegisterComponent,
    PasswordChangeComponent,
    PasswordRefactorComponent,
    AuthConfirmationAccountComponent,
    ImageDescriptionComponent,
    ImagePredictionComponent
  ],
  providers: [
  ]
})
export class AuthModule { }
