<div class="password-change-container">
  <h2 mat-dialog-title>{{ 'auth.change password.title' | translate | titlecase }}</h2>
  <form [formGroup]="passwordChangeForm">
    <mat-dialog-content>
      <div fxLayout="column" fxLayoutGap="1.0rem">
        <mat-form-field>
          <mat-label>{{ 'auth.change password.password current' | translate | titlecase }}</mat-label>
          <input matInput type="password" placeholder="{{ 'auth.change password.password current' | translate | titlecase }}" formControlName="password_current">
          <mat-hint>{{ 'app.forms.is required' | translate: {item: ('auth.change password.password current' | translate | titlecase)} }}</mat-hint>
          <mat-error *ngIf="passwordChangeForm.controls.password_current.errors?.required && passwordChangeForm.controls.password_current.touched">
            <span>{{ 'app.forms.is required' | translate: {item: ('auth.change password.password current' | translate | titlecase)} }}</span>
          </mat-error>
        </mat-form-field>
        <mat-form-field>
          <mat-label>{{ 'auth.change password.password replace' | translate | titlecase }}</mat-label>
          <input matInput type="password" placeholder="{{ 'auth.change password.password replace' | translate | titlecase }}" formControlName="password_replace">
          <mat-hint>{{ 'app.forms.is required' | translate: {item: ('auth.change password.password replace' | translate | titlecase)} }}</mat-hint>
          <mat-error *ngIf="passwordChangeForm.controls.password_replace.errors?.pattern && passwordChangeForm.controls.password_replace.touched">
            <span>{{ 'app.forms.is not secure' | translate: {item: ('auth.change password.password replace' | translate | titlecase)} }}</span>
          </mat-error>
          <mat-error *ngIf="passwordChangeForm.controls.password_replace.errors?.required && passwordChangeForm.controls.password_replace.touched">
            <span>{{ 'app.forms.is required' | translate: {item: ('auth.change password.password replace' | translate | titlecase)} }}</span>
          </mat-error>
        </mat-form-field>
        <mat-form-field>
          <mat-label>{{ 'auth.change password.password confirm' | translate | titlecase }}</mat-label>
          <input matInput type="password" placeholder="{{ 'auth.change password.password confirm' | translate | titlecase }}" formControlName="password_confirm">
          <mat-hint>{{ 'app.forms.is required' | translate: {item: ('auth.change password.password confirm' | translate | titlecase)} }}</mat-hint>
          <mat-error *ngIf="passwordChangeForm.controls.password_confirm.errors?.mismatch && passwordChangeForm.controls.password_confirm.touched">
            <span>{{ 'app.forms.does not match' | translate: {item: ('auth.change password.password confirm' | translate | titlecase)} }}</span>
          </mat-error>
          <mat-error *ngIf="passwordChangeForm.controls.password_confirm.errors?.required && passwordChangeForm.controls.password_confirm.touched">
            <span>{{ 'app.forms.is required' | translate: {item: ('auth.change password.password confirm' | translate | titlecase)} }}</span>
          </mat-error>
        </mat-form-field>
      </div>
    </mat-dialog-content>
    <mat-dialog-actions>
      <button fxFill mat-raised-button color="primary" (click)="changePassword()" [disabled]="passwordChangeForm.invalid">
        <span>{{ 'app.actions.accept' | translate | titlecase }}</span>
      </button>
    </mat-dialog-actions>
    <br>
  </form>
</div>
