<div fxFill fxLayout="column" class="auth-container">

  <div fxFlex="84px" fxLayoutAlign="end center" class="mat-elevation-z2 auth-container-navbar">
    <mat-toolbar fxLayoutAlign="space-between center">

      <div fxFlex fxLayout="row" fxLayoutAlign="end center" >
        <button mat-flat-button color="accent" routerLink='/auth/login' *ngIf="router.url.includes('/auth/register') || router.url.includes('/example') ">
          <strong>{{ 'auth.login.login' | translate | uppercase }}</strong>
        </button>
        <!-- <button mat-flat-button color="accent" routerLink='/auth/register' *ngIf="router.url.includes('/auth/login')">
          <strong>{{ 'auth.login.register' | translate | uppercase }}</strong>
        </button> -->
        <button mat-flat-button color="accent" routerLink='/example' *ngIf="router.url.includes('/auth')">
          <strong>{{ 'auth.image.example' | translate | uppercase }}</strong>
        </button>
      </div>

    </mat-toolbar>
  </div>
  <div fxFlex>
    <router-outlet (activate)="setScrollUp()"></router-outlet>
  </div>
  <app-footer></app-footer>
</div>
