import { Component, OnInit, ApplicationRef } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { merge, interval } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';

import { TranslateService } from '@ngx-translate/core';
import { NotificationService } from '@shared/services/notification.service';

import { SwUpdate, VersionDetectedEvent, VersionReadyEvent } from '@angular/service-worker';

import { environment } from '@env/environment';
import { Logger, UntilDestroy, untilDestroyed } from '@core';

// import { CognitoService } from './cognito.service';

const log = new Logger('App');

@UntilDestroy()
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  constructor(
    private router: Router,
    private appRef: ApplicationRef,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private translateService: TranslateService,
    private notificationService: NotificationService,
    private swUpdate: SwUpdate) {

    // Setup update notificator
    this.updateClient();
    this.checkUpdate();
  }

  ngOnInit() {
    // Setup logger
    if (environment.production) {
      Logger.enableProductionMode();
    }

    const onNavigationEnd = this.router.events.pipe(filter(event => event instanceof NavigationEnd));

    // Change page title on navigation or language change, based on route data
    merge(this.translateService.onLangChange, onNavigationEnd)
      .pipe(
        map(() => {
          let route = this.activatedRoute;
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        filter(route => route.outlet === 'primary'),
        switchMap(route => route.data),
        untilDestroyed(this)
      )
      .subscribe(event => {
        const title = event.title;
        if (title) {
          this.titleService.setTitle(this.translateService.instant(title));
        }
      });
  }

  updateClient() {
    if (!this.swUpdate.isEnabled) {
      return;
    }

    this.swUpdate.versionUpdates.subscribe((event) => {

      log.info('[SWUPDATE] Event', event);

      if (event.type === 'VERSION_DETECTED') {
        log.info('[SWUPDATE] appData', (event as VersionDetectedEvent).version.appData);
        log.info('[SWUPDATE] versionHash', (event as VersionDetectedEvent).version.hash);
      }

      if (event.type === 'VERSION_READY') {
        log.info('[SWUPDATE] currentVersion', (event as VersionReadyEvent).currentVersion);
        log.info('[SWUPDATE] latestVersion', (event as VersionReadyEvent).latestVersion);

        this.notificationService.open(
          'app.messages.update available for the webapp',
          'app.actions.refresh', {
            duration: 24 * 60 * 60 * 1000,
            horizontalPosition: 'start',
            verticalPosition: 'top'
          }
        )
        .subscribe(() => location.reload());
      }
    });

    this.swUpdate.activateUpdate()
      .then((status) => log.info('[SWUPDATE] Update version', status))
      .catch((error) => { log.warn('[SWUPDATE] Error in update', error)
    });
  }

  checkUpdate() {
    this.appRef.isStable.subscribe((isStable) => {
      if (isStable) {
        const timeInterval = interval(1 * 60 * 60 * 1000); // hh * mm * ss * ms

        timeInterval.subscribe(() => {
          this.swUpdate.checkForUpdate().then(() => log.debug(`[SWUPDATE] Checked`));
        });
      }
    });
  }
}
