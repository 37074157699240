import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { Shell } from '@app/shell/shell.service';

const routes: Routes = [
  Shell.childRoutes([
    { path: 'widgets', loadChildren: () => import('./widgets/widgets.module').then(m => m.WidgetsModule) },
    { path: 'input', loadChildren: () => import('./image-input/image-input.module').then(m => m.ImageInputModule) },
    { path: 'image', loadChildren: () => import('./image-description/image-description.module').then(m => m.ImageDescriptionModule) },
    { path: 'images', loadChildren: () => import('./image-listing/image-listing.module').then(m => m.ImageListingModule) }
  ]),
  // Fallback when no prior route is matched
  { path: '**', redirectTo: '', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule],
  providers: []
})
export class AppRoutingModule { }
