import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

import { IDialogData } from '../dialogs.interfaces';


@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss']
})
export class MessageComponent {

  title: string;
  message: string;
  acceptButton: string;

  constructor(@Inject(MAT_DIALOG_DATA) data: IDialogData) {
    this.title = data.title;
    this.message = data.message;
    this.acceptButton = data.cancelButton || 'app.actions.accept';
  }

}
