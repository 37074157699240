<div class="auth-register-container" fxLayout="column">
  <div fxLayout="row" fxFlex="100" fxLayoutAlign="center">
    <form [formGroup]="registerForm" fxFlex="30%" fxFlex.lt-md="90%" fxLayout="column" style="margin-top: 50px;">
      <mat-form-field>
        <mat-label>{{ 'users.user.name' | translate }}</mat-label>
        <input matInput [formControlName]="RegisterFormFields.name">
      </mat-form-field>
      <mat-form-field>
        <mat-label>{{ 'users.user.surname' | translate }}</mat-label>
        <input matInput [formControlName]="RegisterFormFields.surname">
      </mat-form-field>
      <mat-form-field>
        <mat-label>{{ 'users.user.email' | translate }}</mat-label>
        <input matInput [formControlName]="RegisterFormFields.email">
      </mat-form-field>
      <mat-form-field>
        <mat-label>{{ 'users.user.phone' | translate }}</mat-label>
        <input matInput [formControlName]="RegisterFormFields.phone">
      </mat-form-field>
      <mat-form-field>
        <mat-label>{{ 'users.user.username' | translate }}</mat-label>
        <input matInput [formControlName]="RegisterFormFields.username">
      </mat-form-field>
      <mat-form-field>
        <mat-label>{{ 'users.user.password' | translate }}</mat-label>
        <input matInput [type]="hidePassword ? 'password' : 'text'" [formControlName]="RegisterFormFields.password">
        <div fxLayout="row" matSuffix>
          <mat-icon [matTooltip]="'auth.register.password_restriction' | translate" matTooltipPosition="below">info</mat-icon>
          <mat-icon (click)="hidePassword = !hidePassword">{{ hidePassword ? 'visibility_off' : 'visibility' }}</mat-icon>
        </div>
      </mat-form-field>

      <button mat-flat-button color="primary" [disabled]="registerForm.invalid" (click)="register()">{{ 'auth.login.register' | translate | capitalize }}</button>
    </form>
  </div>
</div>
