import { Component } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MismatchValidator } from '@app/@shared/validators/mismatch-validator';

import { AWSAuthService } from '@app/@core/services/aws/auth.service';
import { NotificationService } from '@app/@shared/services/notification.service';

import { passwordSetting } from '@env/permissions-settings';


@Component({
  selector: 'app-password-change',
  templateUrl: './password-change.component.html',
  styleUrls: ['./password-change.component.scss']
})
export class PasswordChangeComponent {

  passwordChangeForm: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<any>,
    private awsAuthService: AWSAuthService,
    private notificationService: NotificationService) {

    this.createForm();
  }

  private createForm() {
    this.passwordChangeForm = this.formBuilder.group({
      password_current: ['', Validators.required],
      password_replace: ['', [Validators.required, Validators.pattern(passwordSetting)]],
      password_confirm: ['']
    });

    this.passwordChangeForm.controls.password_confirm.setValidators([
      Validators.required,
      MismatchValidator.mismatch(this.passwordChangeForm.controls.password_replace)
    ]);
  }

  changePassword() {
    this.awsAuthService.changePassword(
      this.passwordChangeForm.value.password_current,
      this.passwordChangeForm.value.password_replace
    ).then(
      () => {
        this.notificationService.open('auth.change password.password updated correctly');
        this.dialogRef.close();
      }
    ).catch(
      (error) => this.notificationService.open(error.err.message)
    );
  }
}
