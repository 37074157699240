import { AbstractControl, ValidatorFn } from '@angular/forms';

export class MismatchValidator {

  static mismatch(mainInput: AbstractControl): ValidatorFn {

    return (input: AbstractControl): { [key: string]: boolean } | null => {
        if (input.value !== mainInput.value) {
            return { 'mismatch': true };
        }

        return null;
    };
  }
}