<div fxFlex fxLayout="column" fxLayoutGap="0.5rem" class="files-upload-container">

  <!-- RESTRICTIONS INFO -->
  <div fxLayout="row" fxLayoutAlign="end " fxLayoutGap="0.5rem" class="dropzone-restrictions" *ngIf="showRestrictions">
    <span *ngIf="fileFormatsRestriction"><strong>{{ 'upload-files.formats-restriction' | translate | titlecase }}:</strong> {{ fileFormatsRestriction }}</span>
    <mat-divider [vertical]="true" *ngIf="fileFormatsRestriction"></mat-divider>
    <span *ngIf="fileItemsRestriction"><strong>{{ 'upload-files.item-restriction' | translate | titlecase }}:</strong> {{ fileItemsRestriction }}</span>
    <mat-divider [vertical]="true" *ngIf="fileItemsRestriction"></mat-divider>
    <span *ngIf="fileSizeRestriction"><strong>{{ 'upload-files.size-restriction' | translate | titlecase }}:</strong> {{ fileSizeRestriction }} MB</span>
  </div>

  <!-- DROPZONE -->
  <div class="dropzone" (dragover)="onDropzoneOver($event)" (dragleave)="onDropzoneOut($event)" [ngClass]="{ 'dropzone-over': inDropzone }">
    <input #fileDropReference fxFlex fxFill multiple type="file" [accept]="fileFormatsRestriction" (change)="fileBrowseHandler($event.target.files)" [disabled]="disabled"/>
    <div fxFlex fxFill fxLayout="column" fxLayoutAlign="center center">
      <span>{{ title | translate | titlecase }}</span>
      <span>{{ subtitle | translate }}</span>
      <mat-icon>cloud_upload</mat-icon>
    </div>
  </div>

  <!-- FILES -->
  <div fxLayout="column">
    <ng-container *ngFor="let file of files; let i = index">
      <div fxFlex fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="0.5rem">
        <img class="filename-icon" [src]="file.url" *ngIf="file.url" />
        <div class="filename-title" [matTooltip]="file.name" matTooltipPosition="left">
          {{ file?.name }}
          ({{ formatBytes(file?.size) }})
        </div>
        <div fxFlex></div>
        <button mat-icon-button (click)="deleteFile(i)" [disabled]="disabled">
          <mat-icon>clear</mat-icon>
        </button>
      </div>
    </ng-container>
  </div>

  <!-- ACTIONS -->
  <div fxLayout="row" fxLayoutAlign="end center" *ngIf="files.length > 0 && showActions">
    <button mat-button (click)="resetFiles()">{{ buttonReset | translate | titlecase }}</button>
    <button mat-raised-button color="primary" (click)="sendFiles()">{{ buttonUpload | translate | titlecase }}</button>
  </div>
</div>
