import { Component, Input, Output, OnInit, SimpleChanges, EventEmitter } from '@angular/core';

export declare class PageEvent {
  /** The current page index. */
  pageIndex: number;
}

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.css'],
})
export class PaginationComponent implements OnInit {
  /** The number of records to display */
  @Input()
  pageSize = 5;

  /** Current page */
  @Input()
  currentPage = 1;

  /** Display the Next/Previous buttons */
  @Input()
  nextPreviousButtons = true;

  @Input()
  hasPreviousPage = false;

  @Input()
  hasNextPage = false;

  @Output() page = new EventEmitter<PageEvent>();

  constructor() {}

  ngOnInit(): void {
  }

  /** Set page number */
  selectPageNumber(pageNumber: number) {
    this.currentPage = pageNumber;
  }

  /** Set next page number */
  next() {
    if (this.hasNextPage){
      const nextPage = this.currentPage + 1;
      this.page.emit({pageIndex: nextPage})
    }
  }

  /** Set previous page number */
  previous() {
    if (this.hasPreviousPage){
      const previousPage = this.currentPage - 1;
      // console.log(previousPage >= 1 && this.selectPageNumber(previousPage));
      this.page.emit({pageIndex: previousPage})
    }
  }


}
