<!-- <div class="auth-login-container" fxLayout="column">
  <h2 mat-dialog-title>{{ 'auth.refactor password.title' | translate }}</h2>
  <div fxLayout="columns" fxFlex="100" fxLayoutAlign="center" fxLayoutGap="1.0rem">
    <form [formGroup]="passwordRefactorForm" fxFlex="30%" fxFlex.lt-md="90%" fxLayout="column" style="margin-top: 50px;">
       <mat-form-field>
        <mat-label>{{ 'auth.login.password' | translate }}</mat-label>
        <input matInput [type]="hidePassword ? 'password' : 'text'" [formControlName]="PasswordRefactorFormFields.password">
        <div fxLayout="row" matSuffix>
          <mat-icon [matTooltip]="'auth.register.password_restriction' | translate" matTooltipPosition="below">info</mat-icon>
          <mat-icon (click)="hidePassword = !hidePassword">{{ hidePassword ? 'visibility_off' : 'visibility' }}</mat-icon>
        </div>
      </mat-form-field>

      <button mat-flat-button color="primary" [disabled]="passwordRefactorForm.invalid" (click)="changePassword()">{{ 'app.actions.confirm' | translate }}</button>
    </form>
  </div>
</div> -->

<div class="auth-password-refactor-container" fxLayout="column">
  <div class="mat-card-title">{{ 'auth.refactor password.title' | translate | capitalize }}</div>
  <div fxLayout="row" fxLayoutAlign="center">
    <form [formGroup]="passwordRefactorForm" fxFlex fxLayout="column" style="margin-top: 50px;">
      <mat-form-field>
        <mat-label>{{ 'auth.login.password' | translate | capitalize }}</mat-label>
        <input matInput [type]="hidePassword ? 'password' : 'text'" [formControlName]="PasswordRefactorFormFields.password">
        <div fxLayout="row" matSuffix>
          <mat-icon (click)="hidePassword = !hidePassword">{{ hidePassword ? 'visibility_off' : 'visibility' }}</mat-icon>
        </div>
      </mat-form-field>
      <app-password-hints [formControl]="passwordRefactorForm.controls.password"></app-password-hints>

      <button mat-flat-button color="primary" [disabled]="passwordRefactorForm.invalid" (click)="changePassword()">{{ 'app.actions.confirm' | translate | capitalize }}</button>
    </form>
  </div>
</div>


<!-- <div class="password-change-container">
  <h2 mat-dialog-title>{{ 'auth.refactor password.title' | translate }}</h2>
  <form [formGroup]="passwordRefactorForm">
    <mat-dialog-content>
      <div fxLayout="column" fxLayoutGap="1.0rem">

        <mat-label>{{ 'auth.change password.password replace' | translate }}</mat-label>
        <mat-form-field>
          <input matInput [type]="hidePassword ? 'password' : 'text'" placeholder="{{ 'users.user.password' | translate }}" formControlName="password_replace" required>
          <mat-icon matSuffix (click)="hidePassword = !hidePassword">
            {{ hidePassword ? 'visibility_off' : 'visibility' }}</mat-icon>
          <mat-error *ngIf="passwordRefactorForm.controls.password_replace.errors?.required && passwordRefactorForm.controls.password_replace.touched">
            <span>{{ 'app.forms.is required' | translate: {item: ('users.user.password' | translate )} }}</span>
          </mat-error>
        </mat-form-field>

        <div fxLayout="column" fxFlex="50%" fxFlex.sm="100%">
          <div class="password-hints" fxLayout="column">
            <mat-hint class="password-hints-title">
              <span><strong>{{ 'users.register.password hints title' | translate }}</strong></span>
            </mat-hint>
            <mat-hint>
              <mat-icon class="material-icons-outlined check-icon" *ngIf="!passwordRefactorForm.controls.password_replace.errors?.formatLength && passwordRefactorForm.controls.password_replace.value">done</mat-icon>
              <mat-icon class="material-icons pending-icon" *ngIf="passwordRefactorForm.controls.password_replace.errors?.formatLength || !passwordRefactorForm.controls.password_replace.value">close</mat-icon>
              <span>{{ 'users.register.password length' | translate }}</span>
            </mat-hint>
            <mat-hint>
              <mat-icon class="material-icons-outlined check-icon" *ngIf="!passwordRefactorForm.controls.password_replace.errors?.formatUppercase && passwordRefactorForm.controls.password_replace.value">done</mat-icon>
              <mat-icon class="material-icons pending-icon" *ngIf="passwordRefactorForm.controls.password_replace.errors?.formatUppercase || !passwordRefactorForm.controls.password_replace.value">close</mat-icon>
              <span>{{ 'users.register.password uppercase' | translate }}</span>
            </mat-hint>
            <mat-hint>
              <mat-icon class="material-icons-outlined check-icon" *ngIf="!passwordRefactorForm.controls.password_replace.errors?.formatLowercase && passwordRefactorForm.controls.password_replace.value">done</mat-icon>
              <mat-icon class="material-icons pending-icon" *ngIf="passwordRefactorForm.controls.password_replace.errors?.formatLowercase || !passwordRefactorForm.controls.password_replace.value">close</mat-icon>
              <span>{{ 'users.register.password lowercase' | translate }}</span>
            </mat-hint>
            <mat-hint>
              <mat-icon class="material-icons-outlined check-icon" *ngIf="!passwordRefactorForm.controls.password_replace.errors?.formatNumber && passwordRefactorForm.controls.password_replace.value">done</mat-icon>
              <mat-icon class="material-icons pending-icon" *ngIf="passwordRefactorForm.controls.password_replace.errors?.formatNumber || !passwordRefactorForm.controls.password_replace.value">close</mat-icon>
              <span>{{ 'users.register.password number' | translate }}</span>
            </mat-hint>
            <mat-hint>
              <mat-icon class="material-icons-outlined check-icon" *ngIf="!passwordRefactorForm.controls.password_replace.errors?.formatSpecialCharacter && passwordRefactorForm.controls.password_replace.value">done</mat-icon>
              <mat-icon class="material-icons pending-icon" *ngIf="passwordRefactorForm.controls.password_replace.errors?.formatSpecialCharacter || !passwordRefactorForm.controls.password_replace.value">close</mat-icon>
              <span>{{ 'users.register.password special character' | translate }}</span>
            </mat-hint>
          </div>
        </div>
      </div>
    </mat-dialog-content>

    <mat-dialog-actions>
      <button fxFill mat-raised-button color="primary" (click)="changePassword()" [disabled]="passwordRefactorForm.invalid">
        <span>{{ 'app.actions.accept' | translate }}</span>
      </button>
    </mat-dialog-actions>
    <br>
  </form>

</div> -->
