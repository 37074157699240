import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { marker } from '@biesbjerg/ngx-translate-extract-marker';

import { AuthSessionGuard } from './auth.guard';

import { AuthComponent } from './auth.component';
import { AuthLoginComponent } from './login/login.component';
import { AuthRegisterComponent } from './register/register.component';
import { ImageDescriptionComponent } from './image-description/image-description.component';

const routes: Routes = [
  { path: 'auth', component: AuthComponent, children: [
    { path: '', redirectTo: 'login', pathMatch: 'full' },
    { path: 'login', component: AuthLoginComponent, data: { title: marker('auth.login.title') }},
    { path: 'register', component: AuthRegisterComponent, data: { title: marker('auth.register.title') }},
  ], canActivate: [AuthSessionGuard]},
  { path: 'example', component: AuthComponent, children:[
    { path: '', component: ImageDescriptionComponent, data: { title: marker('auth.image.example') }}
  ]}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: []
})
export class AuthRoutingModule { }
