import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Logger } from '@app/@core';

import { PasswordValidator } from '@app/@shared/validators/password-validator';

import { NotificationService } from '@app/@shared/services/notification.service';
// import { UsersService } from '@app/users/users.service';

export enum RegisterFormFields {
  email = 'email',
  username = 'username',
  name = 'name',
  surname = 'surname',
  phone = 'phone',
  password = 'password',
}

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class AuthRegisterComponent implements OnInit {

  log = new Logger(AuthRegisterComponent.name);

  RegisterFormFields = RegisterFormFields;

  isLoading: boolean = false;
  hidePassword: Boolean = true;

  registerForm = new FormGroup({
    [RegisterFormFields.email]: new FormControl(null, [Validators.required, Validators.email]),
    [RegisterFormFields.username]: new FormControl(null, [Validators.required]),
    [RegisterFormFields.name]: new FormControl(null, [Validators.required]),
    [RegisterFormFields.surname]: new FormControl(null, [Validators.required]),
    [RegisterFormFields.phone]: new FormControl(null, [Validators.required]),
    [RegisterFormFields.password]: new FormControl(null, [Validators.required, PasswordValidator.password()]),
  });

  constructor(private notificationService: NotificationService) { }

  ngOnInit() {
  }

  register() {
    // this.isLoading = true;
    // this.usersService.register(this.registerForm.value).then(
    //   (result) => {
    //     console.log(result)
    //     this.notificationService.open('app.messages.successful_register');
    //   },
    //   (error) => {
    //     this.log.error("Error register: ", error);
    //     this.notificationService.open('app.messages.operation_failed');
    //   }
    // )
  }

}
