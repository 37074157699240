import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { Logger } from '@core';

import { COLLETION_MIMETYPES } from './files-mimetypes';

const log = new Logger('ImageUploadComponent');


export interface FileUpload {
  name: string;
  size: number;
  url: string;
  file: File;
}

@Component({
  selector: 'app-image-upload',
  templateUrl: './image-upload.component.html',
  styleUrls: ['./image-upload.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ImageUploadComponent),
      multi: true,
    },
  ],
})
export class ImageUploadComponent implements OnInit, ControlValueAccessor {
  @Input() buttonUpload: string = 'upload-image.upload';
  @Input() buttonReset: string = 'upload-image.reset';
  @Input() showRestrictions: boolean = true;
  @Input() showActions: boolean = false;
  @Input() disabled: boolean = false;
  @Input() fileSizeRestriction: number = 5;                                     // MB
  @Input() fileFormatsRestriction: string[] = ['.jpg', '.png', '.jpeg', '.gif'];// Image formats

  @Input() set fileUrl(url: String) {
    if (url)
      this.file = {url: url} as FileUpload;
  }

  @Output() fileEvent = new EventEmitter<FileUpload>();   // Notification auto
  @Output() uploadEvent = new EventEmitter<FileUpload>(); // Notification manual > button upload


  @ViewChild('fileDropReference', { static: false }) fileDropElement: ElementRef;
  file: FileUpload;

  value: File[] = [];
  isDisabled: boolean = false;
  inDropzone: boolean = false;
  inContextual: boolean = false;

  constructor(private sanitizer: DomSanitizer) { }

  onChange: any = () => { };
  onTouch: any = () => { };

  ngOnInit(): void { }

  writeValue(value: File[]): void {
    this.value = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  fileBrowseHandler(files: File[]) {
    this.prepareFile(files[0]);

    this.inDropzone = false;
  }

  resetFile() {
    this.file = undefined;
  }

  sendFile() {
    this.uploadEvent.emit(this.file);
  }

  prepareFile(file: File) {
    const _file = {} as FileUpload;
    const _reader = new FileReader();

    const _mimetype = this.searchFileMimeType(file.type);

    _file.file = file;
    _file.name = file.name;
    _file.size = file.size;
    _file.url = _mimetype.icon;

    // Check preview option
    if (_mimetype.preview) {
      _reader.readAsDataURL(file);
      _reader.onload = () => {
        _file.url = _reader.result.toString();

        // Check file size
        if (_file.size < (this.fileSizeRestriction * 1024 * 1024)) {
          this.file = _file;
          this.fileEvent.emit(this.file);
        }
      };
    }

    this.fileDropElement.nativeElement.value = '';
  }

  formatBytes(bytes: number, decimals: number = 2) {
    if (bytes === 0)
      return '0 Bytes';

    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  onDropzoneOver(event: DragEvent) {
    event.stopPropagation();
    event.preventDefault();

    this.inDropzone = true;
  }

  onDropzoneOut(event: DragEvent) {
    event.stopPropagation();
    event.preventDefault();

    this.inDropzone = false;
  }

  searchFileMimeType(type: string) {
    let mimeType = COLLETION_MIMETYPES.find(mime =>
      mime.mimetypes.some(item => item === type || item === '*')
    );

    return mimeType;
  }

  sanitizeUri() {
    // return this.file.url ? this.sanitizer.bypassSecurityTrustStyle(`url(${this.file.url })`): '';
    return this.sanitizer.bypassSecurityTrustStyle(`url(${this.file.url })`);
  }
}
