import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { AWSAuthService } from '@app/@core/services/aws/auth.service';
import { NotificationService } from '@app/@shared/services/notification.service';

export enum AuthConfirmationAccountFormFields {
  email = 'email',
  confirmation_code = 'confirmation_code'
}

@Component({
  selector: 'app-confirmation-account',
  templateUrl: './confirmation-account.component.html',
  styleUrls: ['./confirmation-account.component.scss']
})
export class AuthConfirmationAccountComponent implements OnInit {

  ConfirmationAccountFormFields = AuthConfirmationAccountFormFields;

  confirmationAccountForm = new FormGroup({
    [AuthConfirmationAccountFormFields.email]: new FormControl(this.data.email, [Validators.required]),
    [AuthConfirmationAccountFormFields.confirmation_code]: new FormControl(this.data.confirmation_code, [Validators.required]),
  });


  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private dialogRef: MatDialogRef<any>,
    private awsAuthService: AWSAuthService,
    private notificationService: NotificationService
  ) {
    if (this.confirmationAccountForm.valid) {
      this.requestVerificationAccount();
    }
  }

  ngOnInit() {
  }

  requestVerificationCode() {
    this.awsAuthService.resendSignUp(this.confirmationAccountForm.value.email).then(
      (data) => {
        this.notificationService.open(`Confirmation code sent to ${data.CodeDeliveryDetails.Destination}`);
      })
      .catch((error) => this.notificationService.open(error.message));
  }

  requestVerificationAccount() {
    this.awsAuthService.confirmSignUp(
      this.confirmationAccountForm.value.email,
      this.confirmationAccountForm.value.confirmation_code
    ).then(
      (data) => {
        this.notificationService.open('app.messages.successful_confirmation_account');
        this.dialogRef.close(true);
      })
      .catch((error) => {
        if (error.message.includes('Current status is CONFIRMED'))
          this.dialogRef.close(true);

        this.notificationService.open(error.message)
      });
  }

}
