<div *ngIf="image" class="container">
  <mat-card style="flex-direction:row;">
    <mat-card-title style="justify-content: center;">Image: {{image.id}}</mat-card-title>

    <mat-card-content fxLayoutGap="2rem"style="align-items: center !important; flex-direction:row; justify-content: center;"  fxLayout.xs="column" fxLayout.sm="column" fxLayout.md="column">

      <img mat-card-image src={{image.url}} style="height:auto; max-width: 300px; margin-bottom: 0px;" >

      <table mat-table [dataSource]="image.prediction" style="width: 25%;">

        <ng-container matColumnDef="type">
          <th mat-header-cell *matHeaderCellDef>Type</th>
          <td mat-cell *matCellDef="let element">{{element.type}}</td>
        </ng-container>

        <ng-container matColumnDef="prediction">
          <th mat-header-cell *matHeaderCellDef>Prediction</th>
          <td mat-cell *matCellDef="let element">{{element.prediction}}</td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>

    </mat-card-content>
  </mat-card>
</div>

<div *ngIf="SHAP_images" class="container">
  <mat-card fxPad>
    <mat-card-title>Explainability SHAP</mat-card-title>
    <mat-card-content fxLayout.xs="column" fxLayout.sm="column" fxLayout.md="column" class="SHAP_images" fxLayoutGap="1rem" fxLayoutAlign="center">
      <div fxLayout="column" fxFlex *ngFor="let item of SHAP_images.sort()" >
        <div>
          <span style="height:auto; width: 100%; max-width: 100%;">{{item.type}}</span>
        </div>
        <div>
          <img src={{item.url}} style="height:auto; width: 100%; max-width: 100%;"  fxFlex.xs="100%" fxFlex.sm="100%" fxFlex.md="100%">
        </div>
      </div>
    </mat-card-content>
    <br>
    <p>{{"auth.image.shap" | translate}}</p>
  </mat-card>
</div>
