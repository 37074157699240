import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ImageService } from '@app/@shared/api/images.service';
import { Image } from '@app/@shared/models/image';
import { NotificationService } from '@app/@shared/services/notification.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  health_info: string | undefined;
  data_info: string | undefined;
  isLoading = false;

  constructor(
    private imageService: ImageService,
    private router: Router,
    private notification: NotificationService,
    private translateService: TranslateService) { }

  ngOnInit() {

  }

  searchQueryEvent(event: string){
    this.imageService.getImage("id", event).subscribe(
      (response: Image) => {
        if(response.id){
          this.router.navigate(["/image"], {queryParams: { id: response.id}});
        }
        else{
          this.notification.open(
            this.translateService.instant('image.not_found'),
            "accept",
            {duration: 5000}
          )
        }
      }
    )
  }
}
