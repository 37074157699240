<div class="auth-login-container" fxLayout="column">
  <div fxLayout="row" fxFlex="100" fxLayoutAlign="center">
    <form [formGroup]="loginForm" fxFlex="30%" fxFlex.lt-md="90%" fxLayout="column" style="margin-top: 50px;">
      <mat-form-field>
        <mat-label>{{ 'auth.login.email' | translate }}</mat-label>
        <input matInput [formControlName]="LoginFormFields.username">
      </mat-form-field>
      <mat-form-field>
        <mat-label>{{ 'auth.login.password' | translate }}</mat-label>
        <input matInput [type]="hidePassword ? 'password' : 'text'" [formControlName]="LoginFormFields.password">
        <mat-icon matSuffix (click)="hidePassword = !hidePassword">{{ hidePassword ? 'visibility_off' : 'visibility' }}</mat-icon>
      </mat-form-field>

      <button mat-flat-button color="primary" [disabled]="loginForm.invalid" (click)="login()">{{ 'app.actions.enter' | translate | capitalize }}</button>
    </form>
  </div>
</div>
