import { Component, OnInit } from '@angular/core';

export interface TableData{
  split_name: string;
  total: number
  any: number;
  epidural: number;
  intraparenchymal: number;
  intraventricular: number;
  subarachnoid: number;
  subdural: number;
}

const ELEMENT_DATA: TableData[]=[
  {split_name: "Train", total: 602.241, any: 86.346, epidural: 25.416, intraparenchymal: 28.894, intraventricular: 20.964, subarachnoid: 28.540, subdural: 37.732},
  {split_name: "Test", total: 75.280, any: 10.793, epidural: 316, intraparenchymal: 3.612, intraventricular: 2.621, subarachnoid: 3.567, subdural: 4.719},
  {split_name: "Validation", total: 75.281, any: 10.794, epidural: 313, intraparenchymal: 3.612, intraventricular: 2.620, subarachnoid: 3568, subdural: 4.715}
]

@Component({
  selector: 'app-dashboard-table',
  templateUrl: './dashboard-table.component.html',
  styleUrls: ['./dashboard-table.component.scss']
})
export class DashboardTableComponent implements OnInit {
  displayedColumns: string[] = ["split_name", "total", "any", "epidural", "intraparenchymal", "intraventricular", "subarachnoid", "subdural"];
  dataSource = ELEMENT_DATA;

  constructor() { }

  ngOnInit() { }

}
