<div fxLayout="column" fxLayoutGap="0.5erm" class="mat-typography">
  <h2 mat-dialog-title>{{ title | translate | uppercase }}</h2>

  <mat-dialog-content>
    {{ message | translate }}
  </mat-dialog-content>
  <br>
  <mat-dialog-actions align="end">
    <button mat-button [matDialogClose]>{{ acceptButton | translate | titlecase }}</button>
  </mat-dialog-actions>
</div>
