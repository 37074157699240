import { NgModule } from '@angular/core';

import { ImageListingComponent } from './image-listing.component';
import { ImageListItemComponent } from './image-list-item/image-list-item.component';
import { SharedModule } from '@app/@shared';
import { ImageListingRoutingModule } from './image-listing-routing.module';
import { PaginationComponent } from './pagination/pagination.component';

@NgModule({
  imports: [
    SharedModule,
    ImageListingRoutingModule
  ],
  declarations: [
    ImageListingComponent,
    ImageListItemComponent,
    PaginationComponent
  ]
})

export class ImageListingModule { }
