<div fxFlex class="shell-container">
  <mat-sidenav-container fxFlex fxFill>

    <!-- SIDENAV PRINCIPAL > Left -->
    <mat-sidenav #sidenavPrimary [mode]="isMobile ? 'over' : 'side'" [opened]="!isMobile" [disableClose]="!isMobile" ngClass.gt-sm="mat-sidenav-border">
      <app-sidenav-primary></app-sidenav-primary>
    </mat-sidenav>

    <!-- SIDENAV SECUNDARY > Right -->
    <mat-sidenav #sidenavSecundary mode="over" opened="false" disableClose="false" position="end" ngClass.gt-sm="mat-sidenav-border">
      <app-sidenav-secundary (closeSidenavEvent)="toggleSidenavSecundary()"></app-sidenav-secundary>
    </mat-sidenav>

    <mat-sidenav-content fxFlex>
      <mat-sidenav-container fxFlex>
        <mat-sidenav-content fxFlex>
          <app-header
            [isMobile]="isMobile"
            (toggleSidenavPrimaryEvent)="toggleSidenavPrimary()"
            (toggleSidenavSecundaryEvent)="toggleSidenavSecundary()"
            (profileChangePasswordEvent)="profileChangePassword()">
          </app-header>
          <div class="mat-container">
            <router-outlet></router-outlet>
          </div>
          <app-footer></app-footer>
        </mat-sidenav-content>
      </mat-sidenav-container>
    </mat-sidenav-content>
  </mat-sidenav-container>

</div>
