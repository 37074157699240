<app-image-prediction></app-image-prediction>

<div style="width: 100%" class="container">
  <mat-card>
      <mat-card-content >
      <h3>{{"dataset.title" | translate}}</h3>
      <p>
        {{"dataset.paragraph" | translate}}.
        <a href="https://www.kaggle.com/competitions/rsna-intracranial-hemorrhage-detection" target="_blank">Competition Link.</a>
      </p>
    </mat-card-content>
  </mat-card>
</div>


<!-- <div class="image-list" fxLayout="row wrap" fxLayoutGap="0.5rem">
      <app-image-list-item *ngFor="let image of images" fxFlex.xs="calc(50% - 1rem)" fxFlex.sm="calc(50% - 1rem)" fxFlex.md="calc(33% - 1rem)" class="image-list__item" [image]="image" (click)="onImageListItemClick(image)"></app-image-list-item>
    </div> -->
