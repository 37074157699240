// `.env.ts` is generated by the `npm run env` command
// `npm run env` exposes environment variables as JSON for any usage you might
// want, like displaying the version or getting extra config from your CI bot, etc.
// This is useful for granularity you might need beyond just the environment.
// Note that as usual, any environment variables you expose through it will end up in your
// bundle, and you should not use it for any sensitive information like passwords or keys.
import { env } from './.env';

export const environment = {
    production: false,
    serviceWorker: true,
    serverUrl: 'https://api.preproduction.cranialhemorrhage.grupovermon.link/api',
    version: env.npm_package_version + '-preproduction',
    cache: {
      Key: 'http_cache',
      Persistence: 'local', /* local / session / memory */
      Duration: 60 /* minutes */
    },
    webhook: {
      Endpoint: '',
      Key: '',
      Token: ''
    },
    aws: {
      s3: {
        input: {
          region: 'eu-west-1',
          bucket:'cranial-hemorrhage.storage.input.preproduction'
        }
      },
      auth: {
        identityPoolId: 'eu-west-1:404da3f8-0427-4ee8-9217-b58a3028f2d9',
        userPoolId: 'eu-west-1_kSKxIe5hM',
        userPoolWebClientId: '6saha4pdmffc606csrrknv14aa',
        region: 'eu-west-1',
        mandatorySignIn: true,
        oauth: {
          domain: "cranialhemorrhage-application-preproduction.auth.eu-west-1.amazoncognito.com",
          scope: ['email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
          redirectSignIn: "https://preproduction.cranialhemorrhage.grupovermon.link",
          redirectSignOut: "https://preproduction.cranialhemorrhage.grupovermon.link",
          responseType: 'token',
          options: {
              AdvancedSecurityDataCollectionFlag: false
          }
        }
      }
    }
  };
