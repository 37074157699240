<mat-card class="image-container">
    <div class="image-list" fxLayout="row" fxLayoutGap="1rem" fxLayoutAlign="start start">
      <app-image-list-item *ngFor="let image of images" [ngStyle]="{width: getText(), 'max-width': getText()}" fxFlex.xs="calc(50% - 1rem)" fxFlex.sm="calc(50% - 1rem)" fxFlex.md="calc(33% - 1rem)" class="image-list__item" [image]="image" (click)="onImageListItemClick(image)"></app-image-list-item>
    </div>
    <br>
    <app-pagination
      [currentPage]="this.current_page"
      [pageSize]="this.page_size"
      [currentPage]="this.current_page"
      [hasPreviousPage]="this.has_previous_page"
      [hasNextPage]="this.has_next_page"
      (page)="handlePageEvent($event)">
    </app-pagination>
</mat-card>
