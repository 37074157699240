<section class="table-container">
  <table mat-table [dataSource]="dataSource">

    <!-- Name Column -->
    <ng-container matColumnDef="split_name" sticky>
      <th mat-header-cell *matHeaderCellDef> {{"dashboard.table.split_name" | translate | titlecase}} </th>
      <td mat-cell *matCellDef="let element"> {{element.split_name}} </td>
    </ng-container>

    <!-- Position Column -->
    <ng-container matColumnDef="total">
      <th mat-header-cell *matHeaderCellDef> {{"dashboard.table.total" | translate | titlecase}} </th>
      <td mat-cell *matCellDef="let element"> {{element.total}} </td>
    </ng-container>

    <!-- Weight Column -->
    <ng-container matColumnDef="any">
      <th mat-header-cell *matHeaderCellDef> {{"dashboard.table.any" | translate | titlecase}} </th>
      <td mat-cell *matCellDef="let element"> {{element.any}} </td>
    </ng-container>

    <!-- Symbol Column -->
    <ng-container matColumnDef="epidural">
      <th mat-header-cell *matHeaderCellDef> {{"dashboard.table.epidural" | translate | titlecase}} </th>
      <td mat-cell *matCellDef="let element"> {{element.epidural}} </td>
    </ng-container>

    <!-- Symbol Column -->
    <ng-container matColumnDef="intraparenchymal">
      <th mat-header-cell *matHeaderCellDef> {{"dashboard.table.intraparenchymal" | translate | titlecase}} </th>
      <td mat-cell *matCellDef="let element"> {{element.intraparenchymal}} </td>
    </ng-container>

    <!-- Symbol Column -->
    <ng-container matColumnDef="intraventricular">
      <th mat-header-cell *matHeaderCellDef> {{"dashboard.table.intraventricular" | translate | titlecase}} </th>
      <td mat-cell *matCellDef="let element"> {{element.intraventricular}} </td>
    </ng-container>

    <!-- Symbol Column -->
    <ng-container matColumnDef="subarachnoid">
      <th mat-header-cell *matHeaderCellDef> {{"dashboard.table.subarachnoid" | translate | titlecase}} </th>
      <td mat-cell *matCellDef="let element"> {{element.subarachnoid}} </td>
    </ng-container>

    <!-- Symbol Column -->
    <ng-container matColumnDef="subdural">
      <th mat-header-cell *matHeaderCellDef> {{"dashboard.table.subdural" | translate | titlecase}} </th>
      <td mat-cell *matCellDef="let element"> {{element.subdural}} </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
</section>
