
<mat-card style="position: relative; bottom:0; width: 100%;">
  <mat-card-content>
    <footer>
      <h3>TFG: Clasificación de tipos de hemorragias intracraneales mediante técnicas de aprendizaje automático</h3>
      <!-- <address>
        Jose Antonio García Castro <br>
        joseantonio.garcia24@alu.uclm.es
      </address> -->
      <p>Jose Antonio García Castro</p>
    </footer>
  </mat-card-content>
</mat-card>
