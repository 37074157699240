import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Image } from '@app/@shared/models/image'
import { Observable } from 'rxjs';
import { RequestParams, ResultPaginated } from '../models/_results';

@Injectable({
  providedIn: 'root'
})
export class ImageService {
  constructor(private httpClient: HttpClient) { }

  getImage(field: string, value: string): Observable<Image> {
    const _params = new HttpParams().set(field, value);
    return this.httpClient.get<Image>('/images', {params: _params});
  }

  getImages(requestParams: RequestParams): Promise<ResultPaginated<Image>> {
    const _params = requestParams.toHttpParams();
    return this.httpClient.get<ResultPaginated<Image>>("/images", {params: _params}).toPromise();
  }

  getPublicImage(): Observable<Image> {
    return this.httpClient.get<Image>('/public/images');
  }
}
