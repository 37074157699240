import { DEFAULT_CURRENCY_CODE, LOCALE_ID } from "@angular/core";
import { MAT_DATE_LOCALE } from "@angular/material/core";
import { registerLocaleData } from "@angular/common";
import { languageSettings } from "@env/language-settings";

import { I18nService } from "./@core/services/i18n.service";

import localeES from '@angular/common/locales/es';
registerLocaleData(localeES, 'es-ES');                                          // Locale configuration > Usage for suport currency/date pipes

export const APP_SETTINGS = [
  { provide: DEFAULT_CURRENCY_CODE, useValue: 'EUR' },
  { provide: MAT_DATE_LOCALE, useValue: languageSettings.defaultLanguage },
  { provide: LOCALE_ID, useFactory: (i18nService: I18nService) => {             // Locale cant set dynamic > Change require reboot
      i18nService.init();
      return i18nService.language;
    },
    deps: [I18nService]
  }
]
